document.addEventListener("turbo:load", () => {
  var modalButtons = document.querySelectorAll(".open-embed-modal");
  Array.from(modalButtons).forEach(button => {
    var modal = document.querySelector(`[data-modal="${button.dataset.modalId}"]`)
    button.addEventListener("click", event => {
      event.preventDefault()
      modal.dataset.show = true
    })
    document.addEventListener("click", event => {
      if(event.target.classList.contains("embed-modal") || event.target.classList.contains("close-modal")) {
        delete modal.dataset.show
      }
    })
  })
  // if(modalButtons.length > 0) {
  //   modalButtons[0].addEventListener("click", event => {
  //     event.preventDefault()
  //     modal.dataset.show = true
  //   })
  //   document.addEventListener("click", event => {
  //     if(event.target.classList.contains("embed-modal") || event.target.classList.contains("close-modal")) {
  //       delete modal.dataset.show
  //     }
  //   })
  // }
});