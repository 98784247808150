document.addEventListener("turbo:load", () => {
  Array.from(document.querySelectorAll(".dropdown-trigger")).forEach(dropdown => {
    var wrapper = dropdown.parentElement
    document.addEventListener("click", event => {
      delete wrapper.dataset.show
    })
    dropdown.addEventListener("click", event => {
      event.preventDefault()
      if(wrapper.dataset.show) {
        delete wrapper.dataset.show
        return
      }
      // Fix for dropdowns not opening because of 'document' click event
      setTimeout(() => {
        wrapper.dataset.show = true
      }, 1)
    })
  })
});
